<template>
    <!-- spinner -->
    <div v-if="loading">
        <div class="loading-spinner">
            <div class="spinner">
            </div>
        </div>
    </div>

    <!-- Main content -->
    <div v-else class="p-0 ">
        <div class="content" style="background-color: #EDE8E4;">
            <AppNavbar />
            <div class="d-flex justify-content-center">
                <div class="inner-content d-flex justify-content-center">
                    <div class="row m-0 p-0">
                        <h1 class="page-title p-0">Please enter your login credentials.</h1>
                        <div class="p-0" style="margin-bottom: 29px;">
                            <form @submit.prevent="submitForm">
                                <div>
                                    <input class="form-control w-100" type="text" v-model="email"
                                        placeholder="username">
                                </div>
                                <div>
                                    <input class="form-control w-100" type="password" v-model="password"
                                        placeholder="password" style="margin-bottom: 0px;">
                                </div>
                                <div v-if="errorMessage" class="error-message" style="color: red;">
                                    {{ errorMessage }}
                                </div>
                            </form>
                        </div>
                        <div class="col-md-12 btn-wrap p-0 d-flex justify-content-center" style="margin-bottom: 34px;">
                            <button class="btn btn-blue w-100" @click="submitForm">Submit</button>
                        </div>
                        <div class="p-0 text-center">
                            <a class="underline" @click="emailSend" style="margin-bottom: 32px; cursor: pointer;">Forgot
                                your
                                password?</a>
                        </div>
                        <div class="text-center">
                            <img class="btn-back" :src="imageSrc2" alt="Descriptive Text">
                            <span class="underline">
                                <a @click="goBack" style="cursor: pointer;">Go back</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer" style="background-color: #EDE8E4;">
            <div class="text-center">
                <p class="one">{{ name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
// import AppFooter from '@/components/AppFooter.vue';
import Icon from '@/assets/back-icon-black.svg'
import axios from 'axios';

export default {
    name: 'ExcistingCustomerView',
    components: {
        AppNavbar,
        // AppFooter,
    },
    methods: {
        openDeepLink() {
            const deepLink = "myapp:///login";
            window.location.href = deepLink;
        },
        validatePassword() {
            const password = this.password;
            const minLength = /.{8,}/;
            const uppercase = /[A-Z]/;
            const lowercase = /[a-z]/;
            const digits = /\d.*\d/;
            const specialChar = /[!@#$%^&*(),.?":{}|<>]/;

            if (!minLength.test(password)) {
                this.passwordError = 'Password must be at least 8 characters long.';
            } else if (!uppercase.test(password)) {
                this.passwordError = 'Password must contain at least one uppercase letter.';
            } else if (!lowercase.test(password)) {
                this.passwordError = 'Password must contain at least one lowercase letter.';
            } else if (!digits.test(password)) {
                this.passwordError = 'Password must contain at least two numbers.';
            } else if (!specialChar.test(password)) {
                this.passwordError = 'Password must contain at least one special character.';
            } else {
                this.passwordError = '';
            }
        },
        submitForm() {
            this.loading = true;
            this.makeApiRequest()
        },
        emailSend() {
            window.location.href = '#';
            // if (this.email != '') {

            //     this.loading = true

            //     const apiUrl = 'https://server.tidyexit.com.au/api/customers/checkEmail'
            //     const body = {
            //         email: this.email
            //     }

            //     axios.post(apiUrl, body)
            //         .then(response => {
            //             if (response.status == 200) {
            //                 this.$router.push('/foretpasswordemailsent');
            //             }

            //         })
            //         .catch(err => {
            //             this.errorMessage = "Something wrong please try agin"
            //             console.log(err);

            //         })
            //         .finally(() => {
            //             this.loading = false
            //         })

            // } else {
            //     this.errorMessage = "Enter email for password reset"
            // }
        },
        goBack() {
            window.history.back();  // Goes back to the previous page
        },
        makeApiRequest() {
            const apiUrl = 'https://server.edenstore.lk/api/customers/login'
            const body = {
                email: this.email,
                password: this.password
            }

            axios.post(apiUrl, body)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.type == 'success') {
                            this.$router.push('/authconfirmed');
                        } else if (response.data.type == 'Fail1') {
                            this.errorMessage = response.data.message
                        } else if (response.data.type == 'Fail2') {
                            this.errorMessage = response.data.message
                        } else {
                            this.errorMessage = "please try again something wrong"
                        }
                    }

                })
                .catch(() => {
                    this.errorMessage = "please try again something wrong"
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        checkIfLoaded() {
            if (this.dataLoaded) {
                this.loading = false
            }
        },
        preLoadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve();
                img.onerror = () => reject();
                img.src = src
            })
        }
    },
    data() {
        return {
            email: '',
            password: '',
            passwordError: '',
            showError: true,
            imageSrc2: Icon,
            name: '',
            address_line_1: '',
            errorMessage: '',
            loading: true, // Spinner is displayed initially
            dataLoaded: false, // Tracks when the image is fully loaded
        };
    },
    mounted() {
        // Preload icon with a maximum wait of 2 seconds
        const timeout = setTimeout(() => {
            this.loading = false; // Ensure spinner hides after 2 seconds
        }, 2000)

        this.preLoadImage(this.imageSrc2)
            .then(() => {
                clearTimeout(timeout);
                this.dataLoaded = true;
                this.loading = false; // Hide spinner once loaded
            })
            .catch(() => {
                console.error('Failed to load icon');
                this.loading = false; // Ensure spinner hides on failure
            });
        this.openDeepLink();

    },
    created() {
        const storedParams = JSON.parse(localStorage.getItem('requestParams'));
        this.name = storedParams.name;
        // Retrieve the email from local storage
        // const storedParams = JSON.parse(localStorage.getItem('requestParams'));
        // if (storedParams && storedParams.name && storedParams.address_line_1) {
        //     this.name = storedParams.name;
        //     this.address_line_1 = storedParams.address_line_1;
        // } else {
        //     this.name = 'Name not found'; // Fallback in case the email is not found
        //     this.address_line_1 = 'Address not found';
        // }
    }
};
</script>

<style scoped>
/* spinner styles */
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #6a70ff;
    border-top: 12px solid #fefaf2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.one {
    font-weight: 700;
}

.two {
    font-weight: 500;
}

.footer {
    padding-top: 29px;
    padding-bottom: 28px;
    left: 0;
    bottom: 0;
    height: 97px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    /* position: absolute; */
    /* background-color: #EDE8E4; */
    /* background-color: #FEFAF2; */
    font-size: 14px;
    color: black;
    border-top: 1px solid #DED8D3;
}

.error-message {
    margin-top: 20px;
}


.footer p {
    padding: 0px;
    margin: 0px;
}

.container-color {
    background-color: #EDE8E4;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 8px;
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 30px;
    margin: 16px 0;
    margin-bottom: 30px;
}

.content p {
    margin-top: 10px;
    text-align: start;
    color: #FF5454;
}

form {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

input {
    border-radius: 8px !important;
    font-size: 16px;
    padding: 14px 10px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid white;
    box-shadow: 0 2px 5px rgba(128, 128, 128, 0.08),
        0 0px 1px rgba(128, 128, 128, 0.08);
}

input::placeholder {
    color: #8F8F8F;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #8F8F8F;
}

.btn-back {
    margin-right: 10px;
    width: 18px;
    height: 13px;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}

a {
    text-align: center;
    color: black;
    font-size: 16px;
    text-decoration: none;
}

.underline {
    display: inline-block;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
}


.input-wrapper {
    position: relative;
    display: inline-block;
}

.input-wrapper .error-mark {
    position: absolute;
    right: 15px;
    /* Adjust based on your design */
    top: 40%;
    transform: translateY(-50%);
    color: white;
    background-color: #FF5454;
    padding: 0px 7px;
    border-radius: 50%;
    pointer-events: none;
    /* Ensure the exclamation mark doesn't interfere with input */
}
</style>
