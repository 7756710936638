<template>
  <!-- Loading Spinner -->
  <div v-if="loading" class="loading-spinner">
    <div class="spinner"></div>
  </div>

  <!-- Main Content -->
  <div v-else class="p-0 container-color">
    <div class="content">
      <AppNavbar style="background-color: #FEFAF2; color: black;" />
      <div class="d-flex justify-content-center" style="margin-top: 97px;">
        <div class="inner-content text-center">
          <img :src="imageSrc" style="width: 92px; height: 92px; margin-bottom: 28px;" alt="Descriptive Text">
          <h1 class="page-title">We are currently on our way to you</h1>
          <p class="page-description">We have sent a confirmation link to the email address:</p>
          <p class="page-description" style="font-weight: 700;">{{ email }}</p>
          <p class="page-description" style="margin-bottom: 8px !important;">Please click on this link to confirm your
            email address.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
// import AppFooter from '@/components/AppFooter.vue';
import MismatchImage from '@/assets/Frame 627850.svg';

export default {
  name: 'NewCusEmailSent',
  components: {
    AppNavbar,
  },
  data() {
    return {
      imageSrc: MismatchImage,
      email: '',
      loading: true, // Added loading state
    };
  },
  mounted() {
    // Retrieve the email from local storage
    const storedParams = JSON.parse(localStorage.getItem('requestParams'));
    if (storedParams && storedParams.email) {
      this.email = storedParams.email;
    } else {
      this.email = 'No email found'; // Fallback in case the email is not found
    }

    // Preload the image
    const img = new Image();
    img.onload = () => {
      this.loading = false; // Hide the loading spinner
    };
    img.onerror = () => {
      console.error('Failed to load image.');
      this.loading = false; // Hide the spinner even if the image fails to load
    };
    img.src = this.imageSrc;
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === null) {
      next('/start');
    } else {
      next();
    }
  }
};
</script>

<style scoped>
.container {
  text-align: center;
}

.container-color {
  background-color: #FEFAF2;
}

.inner-content {
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 36px;
  max-width: 500px;
}

.content h1 {
  font-size: 35px;
  margin: 16px 0;
}

.content p {
  font-size: 16px;
  padding: 0px 10px;
  color: black;
  margin-bottom: 30px;
}

.btn-blue {
  background-color: #4373F0;
  color: white;
}

/* Spinner Styles */
.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: #FEFAF2;
  /* Optional: match the background color */
}

.spinner {
  border: 8px solid #6a70ff;
  border-top: 12px solid #fefaf2;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>