<template>
    <!-- Loading Spinner -->
    <div v-if="loading" class="loading-spinner">
        <div class="spinner"></div>
    </div>

    <!-- Main Content -->
    <div v-else class="p-0 container-color">
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="d-flex justify-content-center" >
                <div class="inner-content text-center">
                    <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                    <h1 class="page-title">Your email address has been successfully confirmed</h1>
                    <p class="page-description" style="margin-top: 32px; margin-bottom: 32px;">
                        To ensure the security of your account, you must verify your identity to access your digital
                        mailbox. Once verified, you can fully enjoy the benefits of receiving documents digitally
                        without any restrictions.
                    </p>
                    <button class="btn btn-blue" @click="buttonRedirect">
                        Receive a verification code by post
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import ShieldImage from '@/assets/confirm-icon.svg';

export default {
    name: 'ExistingCustomerAuthentication',
    components: {
        AppNavbar,
    },
    data() {
        return {
            imageSrc: ShieldImage,
            loading: true,       // Overall loading state
            dataLoaded: false,   // Tracks if data from API is loaded
            imageLoaded: false,  // Tracks if image is loaded
        };
    },
    created() {
        // const urlParams = new URLSearchParams(window.location.search);
        // const token = urlParams.get('token');

        // if (!token) {
        //     window.location.href = '/';
        //     return;
        // }

        // const apiUrl = 'https://server.tidyexit.com.au/api/customers/getCustomerDetails';
        // const body = { token };

        // axios.post(apiUrl, body)
        //     .then(response => {
        //         const params = {
        //             country: response.data.country,
        //             city: response.data.city,
        //             postalCode: response.data.postalCode,
        //             name: response.data.name,
        //             address_line_1: response.data.address_line_1,
        //             email: response.data.email,
        //         };

        //         localStorage.setItem('requestParams', JSON.stringify(params));
        //         this.dataLoaded = true;
        //         this.checkIfLoaded();
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //         this.dataLoaded = true; // Proceed even if there's an error
        //         this.checkIfLoaded();
        //     });
    },
    mounted() {
        this.openDeepLink();
        // Preload the image
        const img = new Image();
        img.onload = () => {
            this.imageLoaded = true;
            this.checkIfLoaded();
        };
        img.onerror = () => {
            console.error('Failed to load image.');
            this.imageLoaded = true; // Proceed even if the image fails to load
            this.checkIfLoaded();
        };
        img.src = this.imageSrc;
    },
    methods: {
        buttonRedirect() {
            window.location.href = "/confirmationwaiting";
        },
        checkIfLoaded() {
            if (this.imageLoaded) {
                this.loading = false;
            }
        },
        openDeepLink() {
            const deepLink = "myapp:///verification-required";
            window.location.href = deepLink;
        }
    },
    // beforeRouteLeave(to, from, next) {
    //     if (from.name === null) {
    //         next('/start')
    //     } else {
    //         next()
    //     }
    // }
};
</script>

<style scoped>
.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

button:hover {
    background-color: #0056b3;
}

.btn-blue {
    font-size: 16px !important;
    background-color: #4373F0;
    color: white;
}

/* Spinner Styles */
.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 8px solid #6a70ff;
  border-top: 12px solid #fefaf2;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
