<template>
  <!-- Loading Spinner -->
  <div v-if="loading">
    <div class="loading-spinner">
      <div class="spinner"></div>
      <div style="text-align: center; margin-top: 35px;">
        <h1 style="font-size: 30px;margin-bottom: 25px;">Checking <br> your details </h1>
        <p style="font-size: 16px;font-weight: 500;">Please wait a moment, <br> your details will be checked</p>
      </div>
    </div>
  </div>

  <!-- Main Content -->
  <div v-else>
    <div class="content " :style="backgroundStyle">
      <AppNavbar style="color:white;" />
      <div class="d-flex justify-content-center">
        <div class="inner-content">
          <div class="row m-0 p-0">
            <h1 class="page-title">Set up your digital mailbox now.</h1>
            <p class="page-description">
              Which kind of mailbox would you prefer for future deliveries of your documents?
            </p>
            <div class="col-md-12 m-0 p-0 d-flex justify-content-center">
              <button class="btn btn-nolas-default w-100" @click="goToCustomer" type="button">
                Digital mailbox
              </button>
            </div>
            <div class="col-md-12 p-0 m-0 mt-4 text-center">
              <span class="underline">
                <a href="/postal-mailbox-confirmed">Postal mailbox</a>
              </span>
            </div>
            <!-- <div class="col-md-12 p-0 m-0 mt-4 text-center">
              <span class="underline">
                <a href="myapp://welcomeScreenRoute">Postal mailbox</a>
              </span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import backgroundImage from '../assets/epostbox_auth_bg.png';
import axios from 'axios';


export default {
  name: 'StartView',
  data() {
    return {
      loading: true,
      dataLoaded: false,
      token: ''
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };
    },
  },
  methods: {
    goToCustomer() {
      window.location.href = '/customerview'; // Goes back to the previous page
    },
    checkIfLoaded() {
      if (this.dataLoaded) {
        this.loading = false
      }
    },
    preLoadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = () => reject();
        img.src = src
      })
    }
  },
  async created() {

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    // if (!token) {
    //   window.location.href = '/';
    //   return;
    // }

    // Preload the background image first
    this.preLoadImage(backgroundImage)
      .then(() => {
        this.dataLoaded = true;
      })
      .catch(() => {
        console.error('Failed to load background image');
        this.checkIfLoaded();
      })

    const apiUrl = 'https://server.edenstore.lk/api/customers/getCustomerDetails'
    const body = { token };
    this.token = token;

    await axios.post(apiUrl, body)
      .then(response => {
        if (response.data.status != 'fail') {
          const params = {
            // country: response.data.country,
            // city: response.data.city,
            // postalCode: response.data.postalCode,
            name: response.data.name,
            // address_line_1: response.data.address_line_1,
            email: response.data.properties[0].email,
            token: this.token
          }
          localStorage.setItem('requestParams', JSON.stringify(params))
          this.dataLoaded = true;
          this.checkIfLoaded()
        } else {
          window.location.href = '/';
        }

      }).catch((error => {
        console.error('API request failed:', error);
        window.location.href = '/'; // Redirect if API call fails
      }))

    // console.log("2");

    const storedParams = JSON.parse(localStorage.getItem('requestParams'));
    if (storedParams && storedParams.city && storedParams.address_line_1) {
      this.city = storedParams.city;
      this.address_line_1 = storedParams.address_line_1;
    } else {
      this.city = 'City found';
      this.address_line_1 = 'Address not found';
    }
  },
  components: {
    AppNavbar,
    AppFooter
  },
};
</script>

<style scoped>
/* Spinner Styles */
.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 8px solid #6a70ff;
  border-top: 12px solid #fefaf2;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Existing Styles */
.inner-content {
  position: absolute;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 53px;
  bottom: 97px;
  max-width: 500px;
}


.content h1 {
  font-size: 30px;
  margin: 16px 0;
  color: #fefaf2;
  margin-top: 0px;
  margin-bottom: 14px;
}

.content p {
  font-size: 16px;
  padding: 0px 0px;
  color: #fefaf2;
  margin-top: 14px;
  margin-bottom: 14px;
}

a {
  margin-top: 30px;
  text-align: center;
  text-decoration: none;
  color: #fefaf2;
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
