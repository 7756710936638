<template>

    <!-- Loading Spinner -->
    <div v-if="loading">
        <div class="loading-spinner">
            <div class="spinner"></div>
        </div>
    </div>

    <div v-else class="p-0 container-color">
        <div class="content">
            <AppNavbar />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                    <div>
                        <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                        <h1 class="page-title">Are you sure?</h1>
                        <p class="page-description" style="margin-bottom: 51px !important;">OK, your settings have been
                            saved.
                            Your documents will continue to be delivered to your postal mailbox. We may ask you about
                            this again
                            later.
                        </p>
                    </div>
                    <div>
                        <img class="btn-back" :src="imageSrc4" alt="Descriptive Text">
                        <span class="underline">
                            <a @click="goBack">Go back</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import AuthImage from '@/assets/success-icon.svg'
import Icon from '@/assets/back-icon-black.svg'


export default {
    name: 'PostmailboxConfirmed',
    components: {
        AppNavbar,
        AppFooter
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: AuthImage,
            imageSrc4: Icon,
            loading: true,
            dataLoaded: false,
        }
    },
    methods: {
        goBack() {
            window.history.back();  // Goes back to the previous page
        },
        checkIfLoaded() {
            if (this.dataLoaded) {
                this.loading = false
            }
        },
        preLoadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve();
                img.onerror = () => reject();
                img.src = src
            })
        }
    },
    created() {
        const imagesToLoad = [AuthImage, Icon];
        // Preload the background image first
        Promise.all(imagesToLoad.map(this.preLoadImage))
            .then(() => {
                this.dataLoaded = true;
                this.checkIfLoaded()
            })
            .catch(() => {
                console.error('Failed to load background image');
                this.checkIfLoaded();
            })
    }
};
</script>

<style scoped>
/* Spinner Styles */
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #6a70ff;
    border-top: 12px solid #fefaf2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.container {
    text-align: center;
    height: 100svh;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

button {
    padding: 10px;
    background-color: #4373F0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

a {
    margin-top: 16px;
    text-align: center;
    color: black;
    font-size: 19px;
    text-decoration: none;
}

.btn-back {
    margin-right: 10px;
    width: 18px;
    height: 13px;
}

.underline {
    display: inline-block;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
    margin-top: 15%;
}
</style>