<template>
  <!-- Loading Spinner -->
  <div v-if="loading" class="loading-spinner">
    <div class="spinner"></div>
  </div>

  <!-- Main Content -->
  <div v-else class="p-0">
    <div class="content" :style="backgroundStyle">
      <AppNavbar />
      <div class="d-flex justify-content-center" >
        <div class="inner-content">
          <div class="row m-0 p-0">
            <h1 class="page-title">Sure, you have a <br> nolas account, right?</h1>
            <div class="col-md-12 m-0 p-0 d-flex justify-content-center">
              <button id="btnLogin" class="btn btn-nolas-default w-100" @click="goToExistingCustomers"
                style="margin-bottom: 20px;" type="button">Of course, I have</button>
            </div>
            <div class="col-md-12 m-0 p-0 d-flex justify-content-center">
              <button id="btnRegister" class="btn btn-nolas-default w-100" @click="goToNew" type="button">No, I would
                like to set up</button>
            </div>
            <div class="col-md-12 p-0 m-0 mt-4">
              <div class="col-md-12 p-0 m-0 mt-4 text-center go-back-link">
                <img class="btn-back" :src="iconUrl" alt="Back Icon">
                <span class="underline">
                  <a style="color: white;" @click="goBack">Go back</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import Icon from '@/assets/back-icon-white.svg';
import backgroundImage from '../assets/epostbox_auth_bg.png';

export default {
  name: 'CustomersView',
  data() {
    return {
      loading: true,
      backgroundImageUrl: '',
      iconUrl: '',
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.backgroundImageUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
      };
    },
  },
  methods: {
    goToExistingCustomers() {
      this.$router.push('/existing');
    },
    goToNew() {
      this.$router.push('/newcustomer');
    },
    goBack() {
      window.history.back();
    },
  },
  mounted() {
    let imagesLoaded = 0;
    const totalImages = 2;

    // Helper function to check if all images are loaded
    const checkIfAllImagesLoaded = () => {
      imagesLoaded++;
      if (imagesLoaded === totalImages) {
        this.loading = false;
      }
    };

    // Preload the background image
    const bgImg = new Image();
    bgImg.onload = () => {
      this.backgroundImageUrl = backgroundImage;
      checkIfAllImagesLoaded();
    };
    bgImg.onerror = () => {
      console.error('Failed to load background image.');
      checkIfAllImagesLoaded();
    };
    bgImg.src = backgroundImage;

    // Preload the icon
    const iconImg = new Image();
    iconImg.onload = () => {
      this.iconUrl = Icon;
      checkIfAllImagesLoaded();
    };
    iconImg.onerror = () => {
      console.error('Failed to load icon image.');
      checkIfAllImagesLoaded();
    };
    iconImg.src = Icon;
  },
  components: {
    AppNavbar,
    AppFooter
  },
};
</script>

<style scoped>
/* Spinner Styles */
.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 8px solid #6a70ff;
  border-top: 12px solid #fefaf2;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-back {
  margin-right: 10px;
  width: 18px;
  height: 13px;
}


/* Added cursor pointer for the "Go back" link */
.go-back-link {
  cursor: pointer;
}

.inner-content {
  position: absolute;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 53px;
  bottom: 97px;
  max-width: 500px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.content h1 {
  font-size: 30px;
  margin: 16px 0;
  color: #fefaf2;
  margin-bottom: 10px;
  margin-top: 100px;
  padding-bottom: 15px;
}

.content p {
  font-size: 16px;
  padding: 0px 10px;
  color: #fefaf2;
  margin-bottom: 30px;
}

a {
  text-align: center;
  text-decoration: none;
  color: #fefaf2;
  font-size: 16px;
}
</style>
