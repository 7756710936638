<template>
  <div>
    <div class="navbar">
      <img :src="computedImageSrc" alt="Descriptive Text" />
      <div @click="toggleMenu">
        <div v-if="!menuOpen">
          <img :src="dot" style="height: 100%;" alt="Descriptive Text" />
        </div>
        <font-awesome-icon v-else icon="times" class="dots" />
      </div>
    </div>
    <transition name="menu-overlay">
      <FullScreenMenu v-if="menuOpen" :isOpen="menuOpen" @toggle-menu="toggleMenu" />
    </transition>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FullScreenMenu from '@/components/FullscreenMenu.vue';

library.add(faEllipsisV, faTimes);

export default {
  name: 'AppNavbar',
  components: {
    FontAwesomeIcon,
    FullScreenMenu
  },
  computed: {
    computedImageSrc() {
      if (this.menuOpen) {
        return require('@/assets/nolas_logo_white.svg');
      }
      switch (this.$route.name) {
        case 'startpage':
        case 'CustomersView':
          return require('@/assets/nolas_logo_white.svg');
        default:
          return require('@/assets/nolas_logo_black.svg');
      }
    },
    dot() {
      switch (this.$route.name) {
        case 'startpage':
        case 'CustomersView':
          return require('@/assets/dot-icons-white.svg');
        default:
          return require('@/assets/dot-icons.svg');
      }
    }
  },
  data() {
    return {
      menuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    }
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 32px;
  margin-right: 32px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  z-index: 1001;
  height: 67px;
  /* Ensure it stays above the menu */
}

.logo {
  font-size: 25px;
  font-weight: 600;
}

.dots {
  font-weight: 100;
  font-size: 20px;
  cursor: pointer;
  color: white;
}
</style>
